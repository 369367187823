.menu-catalog {
   .main-menu {
      position: relative;
      margin-bottom: 0;
      .catalog-btn {
         border-radius: 50px 0px 0px 50px;
         padding: 11px 18px;
         position: relative;
         height: 48px;
         width: 225px;
         z-index: 11;
         .menu-icon {
            fill: #fff;
            vertical-align: middle;
            margin-right: 5px;
            margin-bottom: 2px;
            height: 24px;
            width: 24px;
         }
         &:hover {
            background-color: $dblue;
            border-color: $dblue;
         }
      }
      .cat-list {
         background-color: $dblue;
         border-radius: 0px 12px 12px 12px;
         box-shadow: 0px 12px 22px rgba(6, 27, 46, 0.31);
         display: flex;
         flex-wrap: wrap;
         margin-top: -24px;
         padding: 45px 36px 70px;
         position: absolute;
         top: 100%;
         left: 0;
         width: 902px;
         z-index: 10;
         opacity: 0;
         transform: scaleY(0);
         transform-origin: 0 0;
         transition: all .5s;
         > li {
            flex-basis: calc((100% - 92px) / 3);
            margin-right: 46px;
            margin-bottom: 11.8px;
            padding-right: 14px;
            padding-bottom: 10px;
            &:nth-child(3n+3) {
               margin-right: 0;
            }
            &.special-link {
               background-color: $green;
               border-radius: 0px 25px 25px 0px;
               margin-bottom: 0;
               padding-bottom: 0;
               width: 247px;
               position: absolute;
               left: 0;
               bottom: 30px;
               @include trans();
               a {
                  padding: 12px 10px 12px 36px;
               }
               &:hover {
                  background-color: $lgreen;
                  a {
                     color: #fff;
                  }
               }
            }
            > a {
               font-weight: 600;
               color: #fff;
               line-height: 21px;
               display: block;
               &:hover {
                  color: $blue;
               }
            }
            &.has-children {
               position: relative;
               >a:after {
                  content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.2426 0.299119C0.852076 0.689643 0.852076 1.32281 1.2426 1.71333L4.77813 5.24887C5.16866 5.63939 5.80182 5.63939 6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465L2.65681 0.299119C2.26629 -0.0914055 1.63312 -0.0914054 1.2426 0.299119Z' fill='white'/%3e%3cpath d='M1.2426 8.7844C1.63312 9.17492 2.26629 9.17492 2.65681 8.7844L6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465C5.80182 3.44413 5.16866 3.44413 4.77813 3.83465L1.2426 7.37019C0.852076 7.76071 0.852076 8.39388 1.2426 8.7844Z' fill='white'/%3e%3c/svg%3e");
                  float: right;
                  margin-left: 5px;
               }
               &:hover {
                  .sub-menu {
                     opacity: 1;
                     visibility: visible;
                  }
               }
            }
         }
         .sub-menu {
            background-color: #fff;
            box-shadow: 0px 4px 20px rgba(9, 39, 66, 0.38);
            border-radius: 7px;
            padding: 28px 30px 28px 36px;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: -28px;
            left: 100%;
            min-width: 318px;
            z-index: 10;
            transition: all .4s;
            &:before {
               border-style: solid;
               border-width: 12.5px 15px 12.5px 0;
               border-color: transparent #ffffff transparent transparent;
               content: "";
               width: 0;
               height: 0;
               position: absolute;
               top: 27px;
               left: -7px;
            }
            li {
               margin-bottom: 15px;
               line-height: 21.8px;
               &:last-child {
                  margin-bottom: 0;
               }
               a {
                  font-weight: 600;
                  line-height: 21.8px;
               }
            }
            &.outwin {
               top: 100%;
               left: 50%;
               transform: translateX(-50%);
               &:before {
                  border-width: 0 12.5px 15px 12.5px;
                  border-color: transparent transparent #ffffff;
                  top: -7px;
                  left: 50%;
                  transform: translateX(-50%);
               }
            }
         }
      }
      .menu-catalog {
         &:hover {
            .cat-list {
               opacity: 1;
               transform: scaleY(1);
            }
         }
      }
   }
}
.row-menu {
   display: flex;
   justify-content: space-between;
   .wrap-menus {
      display: flex;
   }
   .menu-info {
      position: relative;
      margin-bottom: 0;
      .btn-menu-info {
         border-radius: 0 50px 50px 0;
         position: relative;
         height: 48px;
         width: 165px;
         z-index: 11;
         &:hover {
            background-color: $blue;
         }
      }
      .list-info {
         background-color: $blue;
         border-radius: 12px;
         box-shadow: 0px 12px 22px rgba(6, 27, 46, 0.31);
         padding: 50px 56px 18px;
         margin-top: -24px;
         position: absolute;
         top: 100%;
         left: 0;
         z-index: 10;
         opacity: 0;
         transform: scaleY(0);
         transform-origin: 0 0;
         transition: all .5s;
         li {
            margin-bottom: 22px;
            a {
               font-weight: 600;
               color: #fff;
               line-height: 21.79px;
               white-space: nowrap;
               &:hover {
                  color: $main;
               }
            }
         }
      }
      > li {
         &:hover {
            .list-info {
               transform: scaleY(1);
               opacity: 1;
            }
         }
      }
   }
   .header-login {
      a {
         background-color: $dblue;
         border-radius: 50%;
         display: flex;
         justify-content: center;
         align-items: center;
         color: #fff;
         height: 48px;
         width: 48px;
         .user-icon {
            fill: #fff;
            height: 24px;
            width: 24px;
         }
         &:hover {
            background-color: $blue;
         }
      }
   }
}

#search {
   border: 2px solid $blue;
   border-radius: 50px;
   flex-basis: 582px;
   height: 48px;
   .form-control {
      border: none;
      padding: 10px 0px 10px 26px;
      height: 44px;
   }
   .search-icon {
      fill: $dblue;
      height: 24px;
      width: 24px;
   }
   .btn-link {
      background-color: #fff;
      border-radius: 0 50px 50px 0;
      padding: 6.5px 11px;
      line-height: 14px;
   }
}

#cart {
   a {
      background-color: $dblue;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: relative;
      height: 48px;
      width: 48px;
      line-height: 1;   
      &:hover {
         background-color: $blue;
      }
   }
   .cart-icon {
      fill: #fff;
      height: 24px;
      width: 24px;
   }
   #cart-total {
      background-color: $blue;
      border-radius: 50%;
      color: #fff;
      font-family: $ub;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      position: absolute;
      right: 0;
      bottom: -6px;
      z-index: 3;
   }
}

@include mq(lg) {
   #search {
      flex-basis: 390px;
   }
}
@include mq(md) {
   #mobil-btn {
      padding: 0;
   }
   .row-menu {
      .wrap-menus {
         display: none;
      }
   }
   #search {
      flex-basis: 100%;
   }
}