#product-product {
   h1 {
      margin-bottom: 50px;
   }
   .thumbnails {
      margin-bottom: 50px;
   }
   .thumbnail {
      border: none;
      border-radius: 12px;
      padding: 0;
      margin-bottom: 14px;
   }
   .big-image {
      background-color: #fff;
      border-radius: 12px;
      position: relative;
      img {
         border-radius: 12px;
         padding: 0;
      }
   }
   .discount-sticker {
      background-color: $blue;
      border-radius: 0px 16px 16px 0px;
      font-size: 14px;
      font-weight: 700;
      line-height: 160%;
      color: #fff;
      padding: 5px 10px;
      text-align: center;
      width: 62px;
      position: absolute;
      left: 0;
      top: 26px;
      &.stiker-pickup {
         border-radius: 16px 0 0 16px;
         line-height: 120%;
         text-align: right;
         padding: 8px 15px;
         left: auto;
         right: 0;
         width: 123px;
      }
   }
   .image-additional {
      padding-right: 66px;
      .img-item {
         padding: 0 7px;
         .thumbnail {
            margin-bottom: 0;
            img {
               border-radius: 12px;
            }
         }
      }
      .slide-next  {
         border: none;
         background: transparent;
         position: absolute;
         right: 16px;
         top: 50%;
         transform: translateY(-50%);
         .chevron-right {
            fill: $blue;
            height: 29.48px;
            width: 18px;
         }
      }
   }

   .product-info {
      .product-cod {
         font-size: 13px;
         font-weight: 400;
         line-height: 18px;
         margin-bottom: 14px;
         span {
            font-weight: 700;
            color: $dblue;
         }
      }
      .stock {
         font-size: 14px;
         //color: #08a405;
         line-height: 160%;
         margin-bottom: 25px;
         span {
            font-size: 17px;
         }
         &.status-5 {
            color: #F73434;
         }
         &.status-6 {
            color: $blue;
         }
         &.status-7 {
            color: #08a405;
         }
         &.status-8 {
            color: #253A48;
         }
         &.status-9 {
            color: #F79334;
         }
         
      }
      .box-radio {
         display: flex;
         align-items: center;
         margin-bottom: 30px;
         .control-label {
            font-weight: 700;
            color: $dblue;
            margin-right: 23px;
            margin-bottom: 0;
            line-height: 22px;
            + div {
               line-height: 1;
            }
         }
      }
      .radio-inline {
         padding: 0;
         margin: 0 20px 0 0;
         &:last-child {
            margin-right: 0;
         }
         .radio-img {
            padding: 0;
            position: relative;
            line-height: 1;
            .img-thumbnail {
               background-color: #CFDCE2;
               border: 2px solid #CFDCE2;
               border-radius: 50%;
               padding: 0;
               position: relative;
               height: 24px;
               width: 24px;
               z-index: 1;
            }
            input {
               margin: 0;
               position: absolute;
               left: 50%;
               top: 50%;
               transform: translate(-50%, -50%);
               z-index: 0;
               &:checked {
                  + span {
                     &:before {
                        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.292893 1.88389C0.683418 1.49336 1.31658 1.49336 1.70711 1.88389L5.24264 5.41942C5.63316 5.80994 5.63316 6.44311 5.24264 6.83363C4.85212 7.22416 4.21895 7.22416 3.82843 6.83363L0.292893 3.2981C-0.0976311 2.90757 -0.097631 2.27441 0.292893 1.88389Z' fill='white'/%3e%3cpath d='M10.3692 0.292893C10.7597 0.683417 10.7597 1.31658 10.3692 1.70711L5.24264 6.83363C4.85212 7.22416 4.21895 7.22416 3.82843 6.83363C3.4379 6.44311 3.4379 5.80994 3.82843 5.41942L8.95495 0.292893C9.34548 -0.0976313 9.97864 -0.0976312 10.3692 0.292893Z' fill='white'/%3e%3c/svg%3e");
                        height: 19px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 2;
                     }
                  }
               }
            }
         }
      }
      .price-wrap {
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         margin-bottom: 30px;
         .plus-minus {
            background-color: #fff;
            border: 1px solid #CFDCE2;
            border-radius: 40px;
            padding: 0 13px;
            margin-right: 26px;
            height: 55px;
            width: 128px;
            .input-group-btn {
               .btn-default {
                  background: #CFDCE2;
                  border-color: #CFDCE2;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  line-height: 25px;
                  padding: 3px;
                  height: 25px;
                  width: 25px;
                  &:hover {
                     background-color: $blue;
                     .fa {
                        color: #fff;
                     }
                  }
                  &.minus {
                     margin-right: 4px;
                  }
                  &.plus {
                     margin-left: 4px;
                  }
                  .fa {
                     font-size: 12px;
                     color: $dblue;
                     line-height: 1;
                     @include trans();
                  }
               }
            }
            .form-control {
               border: none;
               font-family: $ops;
               font-size: 24px;
               font-weight: 300;
               line-height: 160%;
               padding: 3px;
               text-align: center;
            }
         }
         ul {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            h2 {
               font-size: 42px;
               font-weight: 700;
               margin: 0 10px 0 0;
               line-height: 38.4px;
            }
            .old-price {
               font-size: 16px;
               text-decoration: line-through;
            }
         }
      }
      .btn-row {
         display: flex;
         align-items: center;
         justify-content: space-between;
         flex-wrap: wrap;
         margin-bottom: 15px;
         .btn-green, #oneclick {
            margin-bottom: 15px;
         }
      }
   }

   .text-related {
      margin-bottom: 52px;
   }
   .list-related-prod, .list-looked-prod {
      margin-bottom: 44px;
   }
}
#oneclick {
   #input-payment-telephone {
      font-size: 18px;
      border-right: none;
      height: 48px;
      max-width: 250px;
   }
   #button-oneclick {
      border: 2px solid $dblue;
      border-radius: 50px;
      padding: 10px 15px;
      margin-left: -20px;
      height: 48px;
      min-width: 193px;
      &:hover {
         border-color: $blue;
      }
   }
}

.ask-price-prodpage {
   display: none;
}
#product-product #product .ask-price-prodpage {
   display: block;
}
.ask-price-block {
   margin-bottom: 48px;
   h5 {
      font-size: 24px;
      font-weight: 700;
      color: $main;
      line-height: 160%;
      margin: 0 0 30px;
   }
   #art_ask_price_form {
      display: flex;
      justify-content: space-between;
      .form-group {
         flex-basis: 395px;
         margin-bottom: 0;
         &:first-child {
            flex-basis: 226px;
         }      
      }
      .form-control {
         height: 48px;
      }
      .art_ask_price_name {
         border-radius: 12px;
         padding: 10px 25px;
      }
      .art_ask_price_phone {
         font-size: 18px;
         border-right: none;
         height: 48px;
         max-width: 250px;
      }
      #art_ask_price_submit {
         border: 2px solid $dblue;
         border-radius: 50px;
         padding: 10px 25px;
         margin-left: -20px;
         height: 48px;
         min-width: 165px;
         &:hover {
            border-color: $blue;
         }
      }
   }
}
#art_ask_price {
   .close {
      z-index: 1;
   }
   .modal-header {
      border: none;
      padding: 0;
      min-height: unset;
   }
   h5 {
      font-family: $ub;
      font-size: 32px;
      font-weight: 700;
      color: $dblue;
      line-height: 41.36px;
      margin: 0;
      text-align: center;
   }
}

.short-attributes, #tab-specification {
   .title-attr {
      font-weight: 700;
      color: $dblue;
      margin-bottom: 30px;
   }
   ul {
      columns: 2;
      column-gap: 25px;
      li {
         display: table;
         border-collapse: collapse;
         margin-bottom: 24px;
         span:nth-child(1) {
            font-weight: 600;
            line-height: 21.79px;
            overflow: hidden;
            position: relative;
            display: table-cell;
            width: 100%;
            &:after {
               content: '';
               position: absolute;
               bottom: 0.46875em; /* Подбирается под семейство шрифта на глаз */
               margin-left: 5px; /* Отступ от названия */
               width: 100%;
               height: 0;
               border-bottom: 2px dotted; /* Толщина в em, чтобы размер точек зависил от шрифта */
            }
            img {
               margin-right: 9px;
               vertical-align: middle;
            }
         }
         span:nth-child(2) {
            display: table-cell;
            padding-left: 7px; 
            white-space: nowrap;
            vertical-align: bottom;
         }
      }
   }
   #all-attr {
      font-size: 14px;
      font-weight: 600;
      line-height: 140%;
      color: $dblue;
      text-decoration: underline;
      .chevron-down {
         fill: $dblue;
         margin-left: 15px;
         height: 8px;
         width: 13px;
      }
      &:hover {
         color: $blue;
         text-decoration: none;
         .chevron-down {
            fill: $blue;
         }
      }
   }
}
.short-attributes {
   margin-bottom: 50px;
}
#tab-specification {
   ul {
      column-gap: 125px;
      margin-bottom: 0;
   }
}

.row-tabs {
   margin-bottom: 22px;
   .nav-tabs {
      border-bottom: 4px solid #fff;
      >li {
         border-bottom: 4px solid #fff;
         margin-right: 24px;
         margin-bottom: -4px;
        >a {
           border: none;
           font-size: 18px;
           font-weight: 600;
           line-height: 25px;
           padding: 0;
           margin-bottom: 12px;
           margin: 0 16px 12px;
        }
        &.active {
           border-bottom-color: $blue;
           > a {
              background-color: transparent;
              border: none;
           }
        }
      }
   }
   .tab-content {
      padding-top: 38px;
      padding-bottom: 38px;
   }
}

@include mq(lg) {
   #product-product .product-info .btn-row .btn-green {
      max-width: 176px;
   }
   #oneclick {
      #button-oneclick {
         min-width: 160px;
      }
      #input-payment-telephone {
         max-width: 210px;
         padding-left: 15px;
         padding-right: 15px;
      }
   }

   .short-attributes {
      display: none;
   }
}
@include mq(md) {
   #product-product {
      .product-info {
         .btn-row {
            margin-bottom: 50px;
            .btn-green {
               flex-basis: calc(50% - 5px);
               max-width: none;
            }
            #oneclick {
               flex-basis: calc(50% - 5px);
            }
         }
      }
   }
}
@include mq(sm) {
   #product-product {
      .product-info {
         .btn-row {
            margin-bottom: 50px;
            .btn-green {
               flex-basis: calc(40% - 5px);
               max-width: none;
            }
            #oneclick {
               flex-basis: calc(60% - 5px);
               #input-payment-telephone {
                  max-width: none;
               }
            }
         }
      }
   }
   #tab-specification ul {
      columns: unset;
   }
}
@include mq("650") {
   #product-product {
      h1 {
         font-size: 36px;
      }
      .product-info {
         text-align: center;
         .btn-row, .price-wrap, .box-radio, .price-wrap ul {
            flex-direction: column;
            align-items: center;
         }
         .box-radio {
            .control-label {
               margin-right: 0;
               margin-bottom: 15px;
            }
         }
         .price-wrap {
            ul {
               li {
                  h2 {
                     margin-bottom: 15px;
                     margin-right: 0;
                  }
               }
            }
            .plus-minus {
               margin-right: 0;
               margin-bottom: 30px;
            }
         }
         .btn-row {
            .btn-green {
               max-width: 375px;
            }
         }
      }
      .mod-title-wrap {
         display: block;
         width: 100%;
      }
      .text-related, .mod-title-wrap h3 {
         text-align: center;
         &:after {
            display: none;
         }
      }
      .ask-price-prodpage {
         .col-xs-12 {
            text-align: center;
            width: 100%;
         }
      }
      .ask-price-block {
         #art_ask_price_form {
            flex-direction: column;
            align-items: center;
            .form-group {
               flex-basis: auto;
               margin-bottom: 20px;
               max-width: 375px;
               width: 100%;
            }
         }
      }
   }
}
@include mq("400") {
   .row-tabs {
      .nav-tabs {
         border-bottom: none;
         >li {
            display: block;
            float: none;
            margin-bottom: 12px;
            margin-right: 0;
            text-align: center;
         }
      }
      .tab-content {
         padding-top: 34px;
      }
   }
}
@include mq("380") {
   #product-product {
      .product-info {
         .btn-row {
            #oneclick {
               .input-group {
                  display: block;
                  #input-payment-telephone {
                     border-radius: 50px;
                     border-right: 2px solid #cfdce2;
                     margin-bottom: 15px;
                  }
                  #button-oneclick {
                     margin-left: 0;
                  }
               }
            }
         }
      }
      .ask-price-block {
         #art_ask_price_form {
            .input-group {
               display: block;
               #ask-price-phone {
                  border-radius: 50px;
                  margin-bottom: 20px;
               }
               #art_ask_price_submit {
                  margin-left: 0;
               }
            }
         }
      }
   }
}