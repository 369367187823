.product-allproduct {
   h1 {
      margin-bottom: 76px;
   }
   .list-category {
      margin-bottom: 49px;
      .cat-item {
         background: #FFFFFF;
         box-shadow: 0px 4px 7px rgba(9, 39, 66, 0.21);
         border-radius: 12px;
         text-align: center;
         margin-bottom: 26px;
         padding: 32px 12px 30px;
         position: relative;
         @include trans();
         &:hover {
            background-color: $dblue;
            box-shadow: 0px 4px 25px rgba(9, 39, 66, 0.21);
            a {
               color: #fff;
            }
            .cat-image svg path {
               fill: #fff;
            }
         }
         a {
            font-family: $ub;
            font-weight: 700;
            line-height: 18px;
         }
         .cat-image {
            margin: 0 auto 20px;
            height: 95px;
            width: 95px;
            svg {
               margin: auto;
               height: auto;
               width: 100%;
               path {
                  @include trans();
               }
            }
         }
         .cat-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 70px;
         }
      }
   }
   @include mq("450") {
      h1 {
         font-size: 34px;
      }
   }
   @include mq("380") {
      h1 {
         font-size: 21px;
         margin-bottom: 30px;
         &:after {
            display: none;
         }
      }
      .col-xs-6 {
         width: 100%;
      }
      .list-category {
         .cat-item {
            background-color: transparent;
            box-shadow: none;
            padding: 0;
            margin-bottom: 17px;
            text-align: left;
            .cat-image {
               display: none;
            }
            .cat-title {
               display: block;
               height: auto;
               a {
                  font-family: $ops;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 22px;
               }
               &.has-children {
                  position: relative;
                  &:after {
                     content: "";
                     background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.2426 0.299119C0.852076 0.689643 0.852076 1.32281 1.2426 1.71333L4.77813 5.24887C5.16866 5.63939 5.80182 5.63939 6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465L2.65681 0.299119C2.26629 -0.0914055 1.63312 -0.0914054 1.2426 0.299119Z' fill='%230060A3'/%3e%3cpath d='M1.2426 8.7844C1.63312 9.17492 2.26629 9.17492 2.65681 8.7844L6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465C5.80182 3.44413 5.16866 3.44413 4.77813 3.83465L1.2426 7.37019C0.852076 7.76071 0.852076 8.39388 1.2426 8.7844Z' fill='%230060A3'/%3e%3c/svg%3e");
                     display: inline-block;
                     margin-left: auto;
                     vertical-align: middle;
                     height: 9.07px;
                     width: 8px;
                     position: absolute;
                     right: 0;
                     top: 50%;
                     transform: translateY(-50%);
                  }
               }
            }
            &:hover {
               background-color: transparent;
               box-shadow: none;
               a {
                  color: $main;
               }
            }
         }
      }
      
   }
}