.footer {
   background-color: $dblue;
   color: #fff;
   padding: 90px 0 95px;
   a {
      font-weight: 600;
      color: #fff;
      line-height: 22px;
      &:hover {
         color: $blue;
      }
   }
   p {
      margin-bottom: 15px;
   }
   .footer-row {
      display: flex;
      justify-content: space-between;
      li {
         margin-bottom: 15px;
      }
      .footer-info {
         ul {
            columns: 2;
            
         }
      }
      .social-list {
         margin-bottom: 44px;
         a {
            margin-right: 38px;
            &:last-child {
               margin-right: 0;
            }
            &:hover {
               .soc-icon {
                  fill: $blue;
               }
            }
         }
         .soc-icon {
            fill: #fff;
            height: 30px;
            width: auto;
            @include trans();
         }
         .facebook .soc-icon {
            width: 15px;
         }
         .instagram .soc-icon {
            width: 30px;
         }
         .youtube .soc-icon {
            width: 44px;
         }
         .viber .soc-icon {
            width: 30px;
         }
      }
   }
   @include mq(lg) {
      .footer-row {
         .footer-info {
            ul {
               columns: unset;
            }
         }
      }
   }
   @include mq(md) {
      padding: 60px 0;
      a {
         font-weight: 400;
      }
      .footer-row {
         display: block;
         .footer-logo {
            margin-bottom: 30px;
            img {
               margin: auto;
            }
         }
         .list-unstyled {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-right: -10px;
            li {
               margin-right: 19px;
            }
         }
         .footer-social {
            text-align: center;
            .social-list {
               margin-bottom: 15px;
            }
         }
      }
   }
}