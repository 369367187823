#product-category {
   margin-bottom: 50px;
   h2.h1 {
      margin-bottom: 75px;
   }
   .category-top {
      .cat-item {
         background: #FFFFFF;
         box-shadow: 0px 4px 7px rgba(9, 39, 66, 0.21);
         border-radius: 12px;
         text-align: center;
         margin-bottom: 26px;
         padding: 32px 12px 30px;
         position: relative;
         @include trans();
         &:hover {
            background-color: $dblue;
            box-shadow: 0px 4px 25px rgba(9, 39, 66, 0.21);
            a {
               color: #fff;
            }
            .cat-image svg path {
               fill: #fff;
            }
         }
         a {
            font-family: $ub;
            font-weight: 700;
            line-height: 18px;
         }
         .cat-image {
            margin: 0 auto 20px;
            height: 95px;
            width: 95px;
            svg {
               margin: auto;
               height: auto;
               width: 100%;
               path {
                  @include trans();
               }
            }
         }
         .cat-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 70px;
         }
      }
   }
   
   .wrap-btn-moreprod {
      float: left;
   }
   
   .sort-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;

      @include mq(sm) {
         flex-direction: column;
         align-items: flex-start;
      }
   }

   .form-inline {
      margin-bottom: 48px;

      &:first-child {
         flex: 1;

         @include mq(lg) {
            max-width: 360px;
         }
         @include mq(sm) {
            max-width: none;
            margin-bottom: 0;
         }
      }
      .form-group {
         display: flex;
         align-items: center;
         label {
            color: $dblue;
            line-height: 22px;
            margin-bottom: 0;
            min-width: 103px;
         }
         width: 100%;
      }
      .bootstrap-select {
         margin-left: 15px;
      }
   }
   .pagination {
      margin-bottom: 45px;
   }
}
#sub-cat {
   margin-bottom: 26px;
   .slick-slide {
      > div {
         padding-left: 15px;
         padding-right: 15px;
      }
   }
   .slick-arrow {
      background-color: transparent;
      border: none;
      position: absolute;
      z-index: 3;
      top: calc(50% - 14px);
      height: 29.4px;
         width: 18px;
      .icon {
         fill: $blue;
         height: 29.4px;
         width: 18px;
         @include trans();
      }
      &:hover {
         .icon {
            fill: $dblue;
         }
      }
   }
}

@include mq("780") {
   #product-category {
      #sub-cat {
         padding-left: 20px;
         padding-right: 20px;
         .slick-arrow {
            &.slick-prev {
               left: 0;
            }
            &.slick-next {
               right: 0;
            }
         }
      }
   }
}
@include mq("578") {
   #product-category {
      .sort-wrap {
         display: block;
      }
      .form-inline {
         .form-group {
            display: block;
            label {
               display: block;
               text-align: center;
               margin-bottom: 15px;
            }
         }
         .bootstrap-select {
            margin-left: 0;
            max-width: unset;
            .dropdown-menu>li>a, .form-control:not([class*=col-]) .dropdown-menu>li>a {
               white-space: unset;
            }
         }
      }
   }
}
@include mq("500") {
   #product-category {
      h2.h1 {
         font-family: $ops;
         font-size: 21px;
         font-weight: 600;
         line-height: 29px;
         margin-bottom: 30px;
         &:after {
            display: none;
         }
      }
      .category-top {
         #sub-cat {
            padding: 0;
         }
         .cat-item {
            background-color: transparent;
            box-shadow: none;
            padding: 0;
            margin-bottom: 17px;
            text-align: unset;
            .cat-image {
               display: none;
            }
            .cat-title {
               display: block;
               padding: 0;
               height: auto;
               a {
                  font-family: $ops;
                  font-size: 16px;
                  font-weight: 400;
               }
               
            }
         }
      }
   }
}