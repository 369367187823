.account-menu {
   a {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid $thmb;
      font-size: 18px;
      font-weight: 600;
      color: $main;
      line-height: 25px;
      padding: 0 0 21px;
      margin-bottom: 21px;
      .icon {
         display: inline-block;
         fill: $main;
         margin-right: 22px;
         margin-top: -3px;
         vertical-align: middle;
         height: 24px;
         width: 24px;
         @include trans();
      }
      &:hover, &.active, &.active:hover, &:focus {
         background: none;
         border-color: $thmb;
         color: $blue;
         .icon {
            fill: $blue;
         }
      }
   }
}
.account-simpleedit, .account-password, .account-order, .account-address, .account-simpleaddress-update, .account-simpleaddress-insert {
   h1 {
      margin-bottom: 57px;
   }
   .pagination {
      margin-bottom: 0;
   }
   .footer {
      margin-top: 95px;
   }
}
.account-simpleedit, .account-simpleaddress-update {
   .simpleregister-button-block.buttons {
      background: transparent;
      text-align: left;
      padding: 0;
      .button {
         float: none;
         max-width: 280px;
      }
   }
   .simpleregister-block-content {
      margin: 0;
      padding: 0;
      .form-control {
         max-width: 405px;
      }
   }
}
.account-password {
   .btn-primary {
      max-width: 280px;
   }
}
#account-order {
   .order-item {
      background-color: #fff;
      border: 1px solid $thmb;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 18px 27px 18px 33px;
      margin-bottom: 26px;
      p {
         margin-bottom: 0;
      }
      .order-id {
         font-weight: 700;
         flex-basis: 63px;
         color: $dblue;
         margin-right: 35px;
      }
      .order-products {
         font-size: 14px;
         flex-basis: 424px;
         line-height: 160%;
         margin-right: 35px;
         .title {
            font-weight: 700;
            margin-bottom: 0;
         }
         .link-more-prod {
            text-decoration: underline;
            &:hover {
               text-decoration: none;
            }
         }
      }
      .order-total {
         font-size: 18px;
         font-weight: 700;
         margin-right: 35px;
      }
      .order-status {
         font-size: 14px;
         font-weight: 600;
         flex: auto;
         text-align: right;
         &.status1 {
            color: $blue;
         }
      }
   }
}
#account-address {
   .one-address {
      background-color: #fff;
      border-radius: 12px;
      display: inline-block;
      padding: 30px;
      ul {
         margin-bottom: 0;
         li {
            &.button {
               margin-top: 26px;
            }
         }
      }
      span {
         font-weight: 700;
         display: inline-block;
         min-width: 100px;
      }
   }
   .add-address {
      max-width: 280px;
   }
}

@include mq(lg) {
   .account-simpleaddress-update .simpleregister-button-block.buttons .button, .account-simpleedit .simpleregister-button-block.buttons .button {
      max-width: 405px;
   }
}
@include mq(md) {
   body[class*="account-"] {
      #column-left {
         width: 35%;
      }
      #content {
         width: 65%;
      }
   }
   #account-order {
      .order-item {
         flex-wrap: wrap;
         .order-products {
            flex-basis: auto;
            margin-bottom: 10px;
            margin-right: 0;
            width: 100%;
         }
      }
   }
}
@include mq(sm) {
   body[class^="account-"] {
      #column-left {
         display: block !important;
         width: 100%;
      }
      #content {
         text-align: center;
         width: 100%;
      }
      .account-menu {
         text-align: center;
         a {
            border-bottom: none;
            display: inline-block;
            margin: 0 10px;
         }
      }
      .one-address {
         text-align: left;
      }
   }
   .account-simpleaddress-update .simpleregister-block-content .form-control, .account-simpleedit .simpleregister-block-content .form-control, .account-simpleaddress-update .simpleregister-button-block.buttons .button, .account-simpleedit .simpleregister-button-block.buttons .button {
      max-width: none;
   }
   #account-order {
      .order-item {
         text-align: left;
      }
   }
}
@include mq("380") {
   body[class*="account-"] {
      h1 {
         font-size: 34px;
      }
   }
}