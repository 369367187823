.login .content {
  background: $gray;
  box-shadow: 0px 22px 24px rgba(37, 58, 72, 0.2);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  width: 518px;
  margin: 40px auto 10px;
  padding: 55px 60px 50px;
  overflow: hidden;
  position: relative;

  .close {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  a {
    font-size: 14px;
    font-weight: 400;
    color: $blue;
    text-decoration: underline;
    line-height: 22.4px;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin-bottom: 29px;
    text-align: center;
  }

  h3 {
    font-family: $ub;
    font-size: 24px;
    font-weight: 700;
    color: $dblue;
    line-height: 28px;
    text-align: center;
    margin: 0 0 29px;

    &:after {
      background-color: $dblue;
      content: "";
      display: block;
      margin: 14px auto 0;
      height: 4px;
      width: 126px;
    }
  }

  h4 {
    color: #555;
  }

  .hint {
    color: #999;
    padding: 0;
    margin: 15px 0 7px;
  }

  .forget-form,
  .login-form {
    padding: 0;
    margin: 0;
  }

  select.form-control {
    padding-left: 9px;
    padding-right: 9px;
  }

  .forget-form,
  .register-form {
    display: none;
  }

  .form-actions {
    clear: both;
    border: 0;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-actions>.btn {
    margin-top: -2px;
  }

  .register-form {
    .forget-password {
      color: $main;
    }
  }

  .forget-form {
    .form-group {
      margin-bottom: 30px;
    }
    .create-account {
      margin-bottom: 0;
    }
  }
}
 
.login-options {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.login .content {
  .form-actions .checkbox {
    margin-left: 0;
    padding-left: 0;
  }

  .create-account {
    line-height: 22.4px;
    margin-bottom: 28px;
    overflow: hidden;
  }
}


@media (max-width: 525px) {
  .login {
    .content {
      padding: 55px 15px 50px;
      width: 100%;
      .create-account {
        text-align: center;

        a {
          float: none !important;
          display: block;
        }
      }

      h3 {
        font-size: 22px;
      }
    }
  }

  .forget-password {
    display: inline-block;
  }

  .login .checkbox {
    font-size: 13px;
  }
}