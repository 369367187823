.checkout-simplecheckout {
   h1 {
      margin-bottom: 50px;
   }
   .simple-content {
      margin: 0;
   }
}


#simplecheckout_cart {
   margin-bottom: 60px;
   .item-product {
      background-color: #fff;
      border: 1px solid $thmb;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 11px;
      margin-bottom: 30px;

      .image {
         margin-right: 15px;
         flex-basis: 97px;

         img {
            border-radius: 12px;
            min-width: 76px;
         }
      }

      .prod-name {
         flex-basis: 500px;
         h4 {
            margin: 0; 
         }
         a {
            font-size: 16px;
            font-weight: 700;
            line-height: 160%;
         }
      }

      .price,
      .total-price {
         font-family: $ub;
         font-size: 21px;
         font-weight: 700;
         line-height: 160%;
         color: $blue;
         min-width: 130px;
      }

      .price {
         text-align: right;
      }

      .btn-block {
         border: 1px solid $thmb;
         border-radius: 40px;
         padding: 0 13px;
         margin: 0 35px;
         height: 55px;
         width: 128px;
         .btn-minus,
         .btn-plus {
            background: $thmb;
            border-color: $thmb;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 25px;
            padding: 3px;
            height: 25px;
            width: 25px;
            .fa {
               font-size: 12px;
               color: $dblue;
               line-height: 1;
               @include trans();
            }
            &:hover {
               background-color: $blue;
               .fa {
                  color: #fff;
               }
            }
         }

         .btn-plus {
            margin-left: 4px;
         }

         .btn-minus {
            margin-right: 4px;
         }

         .quantity {
            border: none;
               font-family: $ops;
               font-size: 24px;
               font-weight: 300;
               line-height: 160%;
               padding: 3px;
               text-align: center;
         }
      }

      .remove .btn {
         padding: 0;
         line-height: 1;
         margin-right: 13px;
         .close-icon {
            height: 53px;
            width: 53px;
            &:focus {
               outline: none;
            }
            &:hover {
               fill: $dblue;
            }
         }
      }
   }

   .simplecheckout-cart-total, .simplecheckout-cart-total-value {
      border: none;
      font-size: 14px;
      font-weight: 700;
      padding-left: 0;
      padding-right: 0;
   }

   #total_sub_total, #total_sub_total .simplecheckout-cart-total-value {
      font-family: $ub;
      font-size: 18px;
      font-weight: 700;
      line-height: 197%;
      border: none;
      padding: 0;
   }
   .simplecheckout-cart-total-value {
      margin: 0;
      min-width: 132px;
   }
   .simplecheckout-cart-total-remove {
      display: none;
      max-width: 18px;
      width: auto;
   }

   #total_sub_total {
      margin-top: 42px;
   }
   #total_total, #total_total .simplecheckout-cart-total-value {
      font-size: 24px;
   }
   #total_total {
      span:first-child {
         margin-right: -3px;
      }
   }

   .simplecheckout-cart-buttons {
      margin-top: 20px;
   }
}

.simplecheckout-block {
   .form-control {
      border: 1px solid $thmb;
      height: 56px;
   }
}
#simplecheckout_shipping {
   margin-bottom: 15px;
}

#simplecheckout_shipping_address {
   margin-bottom: 0;
}
#simplecheckout_customer, #simplecheckout_payment {
   margin-bottom: 0;
}
.simplecheckout {
   margin-bottom: 100px;
   .simplecheckout-left-column {
      margin-right: 0;
   }
   .simplecheckout-block-content {
      padding: 0;
      .bootstrap-select {
         max-width: none;
         &.form-control {
            border: none; 
         }
         > .dropdown-toggle, &.open>.dropdown-toggle.btn-default {
            border-radius: 50px;
            &:after {
               content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.6515 0.951795C13.0852 0.385535 12.1671 0.385535 11.6009 0.951795L6.47434 6.07832C5.90808 6.64458 5.90808 7.56267 6.47434 8.12893C7.0406 8.69519 7.95869 8.69519 8.52495 8.12893L13.6515 3.0024C14.2177 2.43614 14.2177 1.51806 13.6515 0.951795Z' fill='%230060A3'/%3e%3cpath d='M1.34782 0.951795C0.781561 1.51806 0.781561 2.43614 1.34782 3.0024L6.47434 8.12893C7.0406 8.69519 7.95869 8.69519 8.52495 8.12893C9.09121 7.56267 9.09121 6.64458 8.52495 6.07832L3.39843 0.951795C2.83217 0.385534 1.91408 0.385535 1.34782 0.951795Z' fill='%230060A3'/%3e%3c/svg%3e ");
               margin-right: 5px;
            }
         }
      }
   }
   .simplecheckout-button-block {
      background: transparent;
      &.buttons {
         margin-top: 48px;
      }
      .simplecheckout-button-right {
         float: none;
         text-align: center;
         max-width: 366px;
         margin: 0 auto;
      }
   }
}

.simpleregister-block-content {
   .bootstrap-select {
      max-width: none;
      
      > .dropdown-toggle, &.open>.dropdown-toggle.btn-default {
         border-radius: 50px;
         &:after {
            content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.6515 0.951795C13.0852 0.385535 12.1671 0.385535 11.6009 0.951795L6.47434 6.07832C5.90808 6.64458 5.90808 7.56267 6.47434 8.12893C7.0406 8.69519 7.95869 8.69519 8.52495 8.12893L13.6515 3.0024C14.2177 2.43614 14.2177 1.51806 13.6515 0.951795Z' fill='%230060A3'/%3e%3cpath d='M1.34782 0.951795C0.781561 1.51806 0.781561 2.43614 1.34782 3.0024L6.47434 8.12893C7.0406 8.69519 7.95869 8.69519 8.52495 8.12893C9.09121 7.56267 9.09121 6.64458 8.52495 6.07832L3.39843 0.951795C2.83217 0.385534 1.91408 0.385535 1.34782 0.951795Z' fill='%230060A3'/%3e%3c/svg%3e ");
            margin-right: 5px;
         }
      }
   }
}

@include mq(md) {
   #simplecheckout_cart {
      .item-product {
         flex-wrap: wrap;
         justify-content: space-between;
         .prod-name {
            flex: 1 1 74%;
         }
         .remove {
            .btn {
               margin-right: 0;
            }
         }
      }
   }
}
@include mq("578") {
   #simplecheckout_cart {
      .item-product {
         flex-direction: column;
         text-align: center;
         position: relative;
         .image {
            margin-right: 0;
            margin-bottom: 15px;
         }
         .prod-name {
            margin-bottom: 15px;
         }
         .price {
            text-align: center;
            margin-bottom: 15px;
         }
         .quantity {
            margin-bottom: 15px;
            .btn-block {
               margin: 0;
            }
         }
         .remove {
            position: absolute;
            top: 10px;
            right: 10px;
            .btn {
               .close-icon {
                  height: 34px;
                  width: 34px;
               }
            }
         }
      }
      .simplecheckout-cart-total-value {
         min-width: unset;
         margin-left: 10px;
      }
   }
   .simplecheckout {
      .simplecheckout-cart-total {
         text-align: center;
      }
      #total_shipping span:first-child {
         max-width: none;
      }
   }
}
@include mq("380") {
   .simplecheckout-block-content .bootstrap-select ul.dropdown-menu>li>a, .simpleregister-block-content .bootstrap-select ul.dropdown-menu>li>a {
      padding: 2px 15px;
      white-space: normal;
   }
}