#information-information {
   h1 {
      margin-bottom: 65px;
   }
   #content {
      margin-bottom: 50px;
   }
   .description-inform {
       h2 {
         font-family: $ub;
         font-size: 36px;
         font-weight: 700;
         color: $blue;
         line-height: 130%;
         margin: 0 0 10px;
      }
      h4 {
         font-size: 24px;
         font-weight: 600;
         line-height: 130%;
         margin: 0 0 31px;
      }
      ul {
         padding-left: 20px;
         li {
            list-style-type: none;
            text-indent: -20px;
            margin-bottom: 20px;
            &:before {
                border: 2px solid $blue;
                content: ""; 
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                height: 9px;
                width: 9px;
                margin-right: 10px;
                margin-bottom: 1px;
                line-height: inherit;
            }
         }
      }
   }
   img {
      border-radius: 12px;
   }
   .next-page-banner {
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 48px;
      margin-top: 45px;
      margin-bottom: 50px;
      height: 182px;
      &.delivery {
         background: url('../image/banner-delivery.jpg') no-repeat;
      }
      &.pay {
         background: url('../image/banner-pay.jpg') no-repeat;
      }
      .next-page-title {
         font-size: 36px;
         font-weight: 700;
         color: #fff;
         line-height: 130%;
      }
   }
}
.wrap-information-4 {
   position: relative;
   overflow: hidden;
   &:after {
      content: url('../image/cube-left.svg');
      position: absolute;
      left: calc(50% - 752px);
      bottom: 245px;
   }
   &:before {
      content: url('../image/cube-right.svg');
      position: absolute;
      right: calc(50% - 752px);
      bottom: 245px;
   }
}
.min-round {
   display: none;
}
.information-information-4 {
   .min-round {
      background-color: #fff;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: calc((50% - 274.5px) - 952px);
      top: 422px;
      height: 549px;
      width: 549px;
   }
   .html-block {
      background: transparent;
      margin-bottom: 78px;
   }
}
.advantages {
   font-weight: 600;
   line-height: 22px;
   margin-bottom: 72px;
   > div {
      display: flex;
      flex-wrap: wrap;
   }
   .text-center {
      margin-bottom: 26px;
   }
   .advantage-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      word-wrap: break-word;
      min-height: 88px;
      margin-top: 10px;
   }
}

@include mq("650") {
   #information-information .next-page-banner {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      padding: 10px 20px;
      .next-page-title {
         font-size: 28px;
      }
   }
}