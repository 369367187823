.list-specials {
   h1 {
      margin-bottom: 50px;
   }
   .image {
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 26px;
      @include trans();
      &:hover {
         box-shadow: 0px 4px 25px rgba(9, 39, 66, 0.21);
      }
   }
   @include mq("500") {
      .col-xs-6 {
         width: 100%;
      }
   }
}
#article {
   #content {
      margin-bottom: 86px;
   }
   h1 {
      margin-bottom: 50px;
   }
   .image {
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 60px;
   }
}