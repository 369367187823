.wrap-slider {
   background: linear-gradient(to top, #fff 55px, transparent 55px);
   position: relative;
   padding-top: 87px;
   &:after {
      background-color: $blue;
      border-radius: 12px 0px 0px 12px;
      margin-left: auto;
      content: "";
      display: block;
      height: 110px;
      max-width: 55%;
      margin-left: auto;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 2;
   }
}
.big-slider {
   .slider-item {
      font-size: 24px;
      line-height: 165%;
      .row {
         display: flex;
         align-items: center;
         flex-wrap: wrap;
      }
      img {
         filter: drop-shadow(43px 45px 28px rgba(9, 39, 66, .3));
         margin-left: auto;
      }
      h3 {
         font-family: $ub;
         font-size: 72px;
         font-weight: 700;
         color: $dblue;
         line-height: 110%;
         margin: 0 0 27px;
      }
      .btn-readmore {
         margin-top: 47px;
      }
   }
}
.slider-nav {
   margin-left: auto;
   max-width: 55%;
   padding-left: 90px;
   padding-right: 50px;
   width: 100%;
   z-index: 3;
   .slick-slide {
      padding-left: 7.5px;
      padding-right: 7.5px;
   }
   .thumb-item {
      cursor: pointer;
      position: relative;
      height: 123px;
      width: 152px;
      &:after {
         background-color: #fff;
         border-radius: 50%;
         content: "";
         display: block;
         position: absolute;
         right: 0;
         bottom: 10px;
         height: 111px;
         width: 114px;
         z-index: -1;
      }
      img {
         filter: drop-shadow(15px 19px 11px rgba(9, 39, 66, 0.3));
         max-width: 120px;
         position: absolute;
         left: 0;
         top: -4px;
      }
   }
   .slide-prev, .slide-next {
      background-color: transparent;
      border: none;
      position: absolute;
      left: 29px;
      bottom: 34px;
      z-index: 5;
      .icon {
         fill: #fff;
         height: 29.4px;
         width: 18px;
      }
   }
   .slide-next {
      left: auto;
      right: 0;
   }
}

@include mq(lg) {
   .wrap-slider {
      &:after {
         max-width: 65%;
      }
   }
   .slider-nav {
      max-width: 65%;
   }
}
@include mq(md) {
   .wrap-slider {
      padding-top: 23px;
      &:after {
         max-width: 83%;
      }
   }
   .big-slider {
      text-align: center;
      .row{
         flex-direction: column;
      }
      .slider-item {
         @include font-size(1.7rem);
         h3 {
            @include font-size(5.21rem);
            &:after {
               display: none;
            }
         }
      }
   }
   .slider-nav {
      max-width: 83%;
      .slick-slide {
         > div {
            text-align: center;
         }
      }
      .thumb-item {
         max-width: 152px;
      }
   }
}
@include mq("578") {
   .slider-nav {
      padding-right: 0;
      padding-left: 70px;
      .slide-next {
         display: none !important;
      }
   }
}
@include mq("450") {
   .banner-img {
      padding-bottom: 45px;
   }
}