.product-thumb {
   background-color: #fff;
   border: 1px solid $thmb;
   border-radius: 12px;
   margin-bottom: 26px;
   padding: 22px 22px 26px 22px;
   position: relative;
   @include trans();
   .discount-sticker {
      background-color: $blue;
      border-radius: 0px 16px 16px 0px;
      font-size: 14px;
      font-weight: 700;
      line-height: 160%;
      color: #fff;
      padding: 5px 10px;
      text-align: center;
      width: 62px;
      position: absolute;
      left: 0;
      top: 13px;
   }
   .image {
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 21px;
      img {
         border-radius: 12px;
         margin: 0 auto;
      }
   }
   .caption {
      height: 102px;
      margin-bottom: 29px;
      h4 {
         font-family: $ub;
         font-size: 16px;
         font-weight: 500;
         color: $main;
         line-height: 165%;
         margin: 0 0 4px;
         max-height: 78px;
         overflow: hidden;
      }
      .stock {
         font-size: 13px;
         font-weight: 600;
         color: $blue;
         line-height: 160%;

         &.status-5 {
            color: #F73434;
         }
         &.status-6 {
            color: $blue;
         }
         &.status-7 {
            color: #08a405;
         }
         &.status-8 {
            color: #253A48;
         }
         &.status-9 {
            color: #F79334;
         }
      }
   }
   .price-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 67px;
      .price-new {
         font-family: $ub;
         font-size: 26px;
         color: $main;
      }
      .price-old {
         font-size: 16px;
         font-weight: 300;
         color: #747474;
         text-decoration-line: line-through;
      }
      .btn-primary {
         padding: 0;
         height: 48px;
         width: 68px;
         padding: 10px 10px;
         .cart-icon {
            fill: #fff;
            height: 20px;
            width: 20px;
         }
         &.ask_price {
            width: 100%;
         }
      }
   }
   &:hover {
      box-shadow: 0px 4px 25px rgba(9, 39, 66, 0.2);
   }
}

.product-thumb.more-products {
   border: none;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: calc(100% - 26px);
   padding: 0;
   .btn-moreprod {
      border: none;
      border-radius: 12px;
      height: 100%;
      padding: 0;
      text-align: center;
      white-space: normal;
      span {
         font-family: $ops;
         font-size: 24px;
         font-weight: 700;
         color: $main;
         line-height: 38.4px;
         margin-top: 19px;
         display: block;
         @include trans();
      }
   }
   .moreprod {
      fill: $blue;
      height: 57px;
      width: 57px;
      @include trans();
   }
   &:hover {
      background-color: $blue;
      .btn-moreprod span {
         color: #fff;
      }
      .moreprod {
         fill: #fff;
      }
   }
}

@include mq(sm) {
   .product-layout, .product-grid {
      &.col-xs-12 {
         width: 50%;
      }
   }
}
@include mq("578") {
   .product-layout, .product-grid {
      &.col-xs-12 {
         width: 100%;
      }
   }
   .product-thumb {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto;
      .caption {
         height: auto;
         margin-bottom: 15px;
      }
   }
}