.feature-wrap {
   padding-top: 67px;
   padding-bottom: 78px;
}
.special-wrap {
   padding-top: 70px;
   padding-bottom: 70px;
}
.mod-title-wrap {
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
   margin-bottom: 52px;
}
.button-all-prod {
   font-weight: 600;
   font-size: 16px;
   color: $dblue;
   line-height: 21.79px;
   &:after {
      content: "";
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.2426 0.299119C0.852076 0.689643 0.852076 1.32281 1.2426 1.71333L4.77813 5.24887C5.16866 5.63939 5.80182 5.63939 6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465L2.65681 0.299119C2.26629 -0.0914055 1.63312 -0.0914054 1.2426 0.299119Z' fill='%230060A3'/%3e%3cpath d='M1.2426 8.7844C1.63312 9.17492 2.26629 9.17492 2.65681 8.7844L6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465C5.80182 3.44413 5.16866 3.44413 4.77813 3.83465L1.2426 7.37019C0.852076 7.76071 0.852076 8.39388 1.2426 8.7844Z' fill='%230060A3'/%3e%3c/svg%3e");
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
      height: 9.07px;
      width: 8px;
      @include trans();
   }
   &:hover {
      color: $blue;
      &:after {
         background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.2426 0.299119C0.852076 0.689643 0.852076 1.32281 1.2426 1.71333L4.77813 5.24887C5.16866 5.63939 5.80182 5.63939 6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465L2.65681 0.299119C2.26629 -0.0914055 1.63312 -0.0914054 1.2426 0.299119Z' fill='%2300A2DE'/%3e%3cpath d='M1.2426 8.7844C1.63312 9.17492 2.26629 9.17492 2.65681 8.7844L6.19235 5.24887C6.58287 4.85834 6.58287 4.22518 6.19235 3.83465C5.80182 3.44413 5.16866 3.44413 4.77813 3.83465L1.2426 7.37019C0.852076 7.76071 0.852076 8.39388 1.2426 8.7844Z' fill='%2300A2DE'/%3e%3c/svg%3e");
      }
      
   }
}

.html-block {
   background: linear-gradient(to top, $dblue 26px, transparent 26px);
   position: relative;
   overflow: hidden;
   .text-html {
      margin-bottom: 96px;
   }
   h1, h3 {
      margin-bottom: 85px;
      min-height: 118px;
   }
   &:before {
      background-color: #fff;
      border-radius: 0px 12px 12px 0px;
      content: "";
      display: block;
      height: calc(100% - 160px);
      width: 1665px;
      position: absolute;
      bottom: 26px;
      left: 0;
   }
   .text-collapse {
      max-width: 88%;
   }
   .more-link {
      color: $dblue;
      cursor: pointer;
      margin-top: 50px;
   }
   .html-block-img {
      .html-image {
         margin-bottom: 26px;
         &:last-child {
            margin-bottom: 0;
         }
      }
      img {
         border-radius: 12px;
      }
   }
}

@include mq(md) {
   .html-block {
      h1, h3 {
         margin-bottom: 90px;
      }
      .text-html {
         margin-bottom: 50px;
      }
      .text-collapse {
         max-width: none;
      }
      .html-block-img {
         display: flex;
         justify-content: center;
         margin: 0 -15px;
         .html-image {
            padding: 0 15px;
            margin-bottom: 0;
         }
      }
   }
   
}
@include mq("578") {
   .mod-title-wrap {
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      h3 {
         margin-bottom: 20px;
         &:after {
            display: none;
         }
      }
   }
   .feature-wrap {
      padding-top: 50px;
      padding-bottom: 25px;
  }
   .special-wrap {
      padding-top: 30px;
      padding-bottom: 30px;
   }
}
@include mq("500") {
   .html-block {
      .html-block-img {
         flex-direction: column;
         .html-image:first-child {
            margin-bottom: 26px;
         }
      }
   }
}