/* The sidebar menu */
#leftSidebar {
   color: #fff;
   height: 100%;
   width: 0;
   position: fixed;
   z-index: 1050;
   top: 0;
   left: 0;
   background-color: $dblue;
   overflow-x: hidden;
   overflow-y: auto;
   padding: 60px 0;
   transition: 0.5s;
   .menu-group {
      padding: 0 20px 0 13px;
      width: 298px;
   }
   a {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      display: block;
      text-decoration: none;
      transition: 0.3s;
      &:hover {
         color: $blue;
      }
   }

   .closebtn {
      position: absolute;
      top: 10px;
      right: 15px;
      .close-icon {
         fill: #fff;
         &:hover {
            fill: $blue;
         }
      }
   }
   .menu-logo {
      display: flex;
      justify-content: space-between;
      margin-bottom: 33px;
   }
   .lang-switch {
      .btn-link.dropdown-toggle {
         color: #fff;
      }
      .chevron-down {
         fill: #fff;
      }
   }
   .menu-account {
      border-bottom: 2px solid #fff;
      display: flex;
      align-items: center;
      padding-bottom: 22px;
      margin-bottom: 20px;
      a {
         font-size: 18px;
         line-height: 20px;
         margin-bottom: 4px;
      }
      .user-icon {
         fill: #fff;
         margin-right: 15px;
         height: 24px;
         width: 24px;
      }
      .login-wrap {
         border-right: 2px solid #fff;
         padding-right: 15px;
         margin-right: 15px;
      }
   }
  
   .main-menu {
      .menu-catalog {
         text-align: left;
         > a {
            border-bottom: 2px solid #fff;
            border-radius: 0;
            font-size: 18px;
            font-weight: 500;
            height: auto;
            line-height: 18px;
            padding: 0;
            padding-bottom: 18px;
            text-align: left;
            &:after {
               content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='12' viewBox='0 0 19 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.78833 1.71149C0.519126 2.98069 0.519127 5.03848 1.78833 6.30768L13.2788 17.7982C14.548 19.0674 16.6058 19.0674 17.875 17.7982C19.1442 16.529 19.1442 14.4712 17.875 13.202L6.38452 1.71149C5.11532 0.442281 3.05753 0.442282 1.78833 1.71149Z' fill='%23FFFFFF'/%3e%3cpath d='M1.78833 29.2887C3.05753 30.5579 5.11532 30.5579 6.38452 29.2887L17.875 17.7982C19.1442 16.529 19.1442 14.4712 17.875 13.202C16.6058 11.9328 14.548 11.9328 13.2788 13.202L1.78833 24.6925C0.519126 25.9617 0.519126 28.0194 1.78833 29.2887Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
               float: right;
               margin-left: auto;
               line-height: 24px;
            }
            &:hover {
               background-color: transparent;
               border-color: transparent;
               border-bottom-color: #fff;
               color: #fff;
            }
         }
         .menu-icon {
            fill: #fff;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 4px;
            margin-right: 3px;
            height: 24px;
            width: 24px;
         }
         .cat-list {
            display: none;
            margin: 20px 0 20px 32px;
            > li {
               margin-bottom: 10px;
               &.has-children {
                  > ul {
                     display: none;
                  }
               }
            }
         }
      }
   }

   .info-link {
      border-bottom: 2px solid #fff;
      margin-top: 20px;
      ul {
         margin-bottom: 0;
      }
      li {
         margin-bottom: 20px;
      }
   }
   .media-block {
      display: flex;
   }
   .menu-open {
      font-size: 14px;
      font-weight: 400;
      margin-top: 22px;
      .open-icon {
         fill: #fff;
         margin-right: 8px;
         margin-top: 1px;
         height: 24px;
         width: 24px;
      }
   }
   .menu-email {
      margin-top: 20px;
      a {
         font-size: 14px;
         font-weight: 400;
         line-height: 24px;
      }
      .media-icon {
         height: 24px;
      }
      .mail-icon {
         fill: #fff;
         margin-right: 8px;
         line-height: 24px;
         height: 24px;
         width: 24px;
      }
   }
   .menu-phones {
      margin-top: 20px;
      margin-bottom: 20px;
      ul {
         margin-bottom: 0;
      }
      a {
         font-size: 14px;
         font-weight: 400;
      }
      .phone-icon {
         fill: #fff;
         margin-right: 8px;
         margin-top: 2px;
         height: 24px;
         width: 24px;
      }
   }
}
