#information-contact {
   margin-bottom: 105px;
   h1 {
      margin-bottom: 50px;
   }
   .top-image {
      border-radius: 12px;
      overflow: hidden;
   }
   .contact-info {
      background-color: #fff;
      border: 1px solid $thmb;
      border-radius: 12px;
      padding: 35px 24px 49px;
      margin-top: -97px;
      .block-title {
         font-weight: 700;
         color: $dblue;
         margin-bottom: 30px;
      }
      .contact-phones {
         margin-bottom: 30px;
         .phone-icon {
            fill: $dblue;
            height: 24px;
            width: 24px;
         }
         a {
            line-height: 22px;
         }
      }
      .contact-mail {
         margin-bottom: 30px;
         .mail-icon {
            fill: $dblue;
            height: 24px;
            width: 24px;
         }
         a {
            display: inherit;
            line-height: 22px;
         }
      }
      .contact-address {
         .address-icon {
            fill: $dblue;
            height: 24px;
            width: 24px;
         }
         .address-text {
            margin-bottom: 15px;
         }
      }
      .contact-form {
         margin-top: 46px;
         .block-title {
            margin-bottom: 22px;
         }
         .col-md-4 {
            margin-bottom: 22px;
         }
         .message {
            textarea {
               border-radius: 12px;
               margin-bottom: 26px;
            }
         }
         .buttons {
            text-align: center;
            .btn-tel {
               line-height: 16px;
               max-width: 280px;
               &:before {
                  content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M21 0.5C21.1486 0.502997 21.2896 0.530161 21.4304 0.597119C21.5756 0.666564 21.7034 0.771198 21.8 0.9C21.8634 0.994393 21.8485 0.966067 21.8 0.9C21.9295 1.0727 22 1.28417 22 1.5C21.9996 1.44673 21.9974 1.42362 22 1.5V16.5C22 17.0523 21.5523 17.5 21 17.5H1C0.447715 17.5 0 17.0523 0 16.5V1.5C0 1.32097 0.0485666 1.14321 0.139924 0.989669C0.326093 0.675979 0.648555 0.507086 1 0.5H21ZM20 3.5L11.6 9.8C11.28 10.04 10.852 10.064 10.5104 9.872L10.4 9.8L2 3.5V15.5H20V3.5ZM4 2.5L11 7.75L18 2.5H4Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
               }
            }
         }
      }
   }
   @include mq(sm) {
      .top-image {
         border-radius: 12px 12px 0 0;
      }
      .contact-info {
         border-radius: 0 0 12px 12px;
         margin-top: 0;
      }
   }
}