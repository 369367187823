.header {
   .header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 35px;
      margin-bottom: 39px;
      .media-block {
         font-size: 14px;
         line-height: 22.4px;
         display: flex;
         align-items: center;
         .media-icon {
            background-color: #F5F5F5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            width: 36px;
         }
      }
      #mobil-btn {
         .hamburger {
            fill: $blue;
            height: 20px;
            width: 27px;
         }
      }
      #logo {
         flex-basis: 195px;
      }
      .header-open {
         .media-icon {
            margin-right: 20px;
            .open-icon {
               fill: $blue;
               height: 22px;
               width: 22px;
            }
         }
      }
      .header-email {
         .media-icon {
            margin-right: 13px;
            .mail-icon {
               fill: $blue;
               height: 17px;
               width: 22px;
            }
         }
      }
      .phones {
         .media-icon {
            margin-right: 13px;
            .phone-icon {
               fill: $blue;
               height: 18.38px;
               width: 18.67px;
            }
         }
         .first-phone {
            .chevron-down {
               display: inline-block;
               fill: $main;
               line-height: 1;
               height: 9px;
               width: 9px;
            }
            &:hover {
               .chevron-down {
                  transform: scale(1, -1);
               }
            }
         }
         .dropdown-menu {
            box-shadow: 0px 6px 10px rgba(6, 27, 46, 0.31);
            border: none;
            border-radius: 7px;
            left: auto;
            margin: 0;
            min-width: auto;
            padding: 8px 0;
            right: 0;
            &:before {
               border-color: transparent transparent #ffffff transparent;border-style: solid;
               border-width: 0 9.5px 12px 9.5px;
               content: "";
               height: 0;
               width: 0;
               position: absolute;
               top: -8px;
               left: 50%;
               transform: translateX(-50%);
            }
            li {
               text-align: right;
               a {
                  padding: 3px 13px;
               }
            }
         }
      }
      .callback {
         flex-basis: 199px;
         @include mq(md) {
            display: none;
         }
      }     
   }
}

.header-row, #leftSidebar {
   .lang-switch {
      .btn-link.dropdown-toggle {
         font-weight: 400;
         padding: 0;
      }
      .dropdown-menu {
         box-shadow: 0px 6px 10px rgba(6, 27, 46, 0.31);
         border: none;
         border-radius: 7px;
         min-width: auto;
         margin: 5px 0 0;
         left: 50%;
         transform: translateX(-50%);
         &:before {
            border-color: transparent transparent #ffffff transparent;
            border-style: solid;
            border-width: 0 9.5px 12px 9.5px;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
         }
         .language-select {
            font-weight: 400;
            padding: 2px 12px;
         }
      }
      .chevron-down {
         display: inline-block;
         fill: $main;
         line-height: 1;
         height: 9px;
         width: 9px;
      }
   }
}

#modalFeedbackHeader {
   .close {
      position: absolute;
      right: 12px;
      top: 12px;
   }
   h3 {
      margin-bottom: 50px;
   }
   .form-control, .btn-primary {
      display: block;
      max-width: 279px;
      margin: 0 auto;
   }
   .form-group {
      text-align: center;
      margin-bottom: 28px;
      &:first-child {
         margin-bottom: 15px;
      }
   }
}

#add-cart-info {
	text-align: center;
   .modal-header {
      border: none;
   }
   .modal-body {
      font-weight: 600;
      padding: 30px 15px;
   }
	.modal-footer {
      border: none;
		text-align: center;
		padding: 15px 30px 40px;
		.btn {
			margin-bottom: 15px;
		}
		.btn-go {
         max-width: none;
      }
	}
}

@include mq(md) {
   .header {
      .header-row {
         padding-top: 32px;
         margin-bottom: 27px;
      }
   }
}