#head_filter {
   padding-top: 14px;
}
#filter_vier {
   .form-control {
      background: #F5F5F5;
      box-shadow: none;
      border: none;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 300;
      height: 46px;
      text-align: center;
   }
   .symbol_sld {
      span {
         border: none;
         box-shadow: none;
         padding: 0;
      }
   }
   .onli_param {
      .text_param {
         margin-left: 10px;
         .count_prod {
            color: $blue;
            margin-left: 6px;
         }
      }
   }
   label {
      margin-bottom: 6px;
   }
   .radio_fv {
      .checka.actionis, .checkb:hover:not(.curs_def) {
         &::before {
            background-color: $blue !important;
         }
      }
   }
   .displ {
      margin: 10px 0 10px;
      .unfoldi {
         border-bottom: none;
         font-size: 14px;
         font-weight: 600;
         color: $blue;
         position: relative;
         &:after {
            background-color: $blue;
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            height: 1px;
            width: calc(100% - 29px);
         }
         &:hover {
            border-bottom: none;
            &:after {
               display: none;
            }
         }
         .strel_hid_fa {
            margin-left: 15px;
         }
      }
   }
   .optv {
      .img_param, .img_param_null {
         border: 2px solid #CFDCE2;
         border-radius: 50%;
         padding: 0;
      }
      .botton_opts.actionis {
         a.actionis {
            position: relative;
            &:before {
               background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.292893 1.88389C0.683418 1.49336 1.31658 1.49336 1.70711 1.88389L5.24264 5.41942C5.63316 5.80994 5.63316 6.44311 5.24264 6.83363C4.85212 7.22416 4.21895 7.22416 3.82843 6.83363L0.292893 3.2981C-0.0976311 2.90757 -0.097631 2.27441 0.292893 1.88389Z' fill='white'/%3e%3cpath d='M10.3692 0.292893C10.7597 0.683417 10.7597 1.31658 10.3692 1.70711L5.24264 6.83363C4.85212 7.22416 4.21895 7.22416 3.82843 6.83363C3.4379 6.44311 3.4379 5.80994 3.82843 5.41942L8.95495 0.292893C9.34548 -0.0976313 9.97864 -0.0976312 10.3692 0.292893Z' fill='white'/%3e%3c/svg%3e");
               background-repeat: no-repeat;
               background-position: center center;
               border: 2px solid $blue;
               border-radius: 50%;
               content: "";
               display: block;
               height: 20px;
               width: 20px;
               position: absolute;
               bottom: -1px;
               left: 2px;
               z-index: 2;
            }
         }
      }
   }
}

#product-search {
   #content {
      margin-bottom: 144px;
   }
   .pagination {
      margin-bottom: 0;
   }
}
.search-title {
   margin-bottom: 47px;
   h1 {
      margin-bottom: 57px;
   }
   p {
      font-size: 18px;
      font-weight: 700;
      color: $dblue;
      margin-bottom: 26px;
      span {
         font-weight: 400;
         color: $main;
      }
   }
}

#mobil_mini i {
   background-color: $dblue !important;
}
